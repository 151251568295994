import Vue from 'vue'
const fallback = require('@/assets/no-image.png')

const getFileUrl = (value, fileField = 'file') => {
  if (!value) {
    return fallback
  }
  if (typeof value === 'string') {
    return value
  }
  if (fileField in value) {
    return value[fileField].url || value[fileField]._url
  }
  if ('file' in value) {
    return value.file.url || value.file._url
  }
  return value.url || value._url
}

const parseFileUrl = (value, fileField = 'file') => {
  const url = getFileUrl(value, fileField)
  const showPhotosFrom = process.env.VUE_APP_SHOW_PHOTOS_FROM || window.localStorage.getItem('showPhotosFrom')
  return showPhotosFrom ? url?.replace('0.0.0.0:', 'localhost:')?.replace(process.env.VUE_APP_SERVER_URL, showPhotosFrom) : url
}

const parseImage = value => parseFileUrl(value, 'size1000')
const parseThumb = value => parseFileUrl(value, 'thumb')

Vue.mixin({
  filters: {
    parseFileUrl,
    parseImage,
    parseThumb
  },
  methods: {
    parseFileUrl,
    parseImage,
    parseThumb
  }
})
